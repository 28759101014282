import axios from "axios";

// user api
const API = axios.create({
  baseURL: "/api/user",
});

// developer api
const DevAPI = axios.create({
  baseURL: "/api/developer",
});

//register
const register = async (userData) => {
  const response = await API.post("/register", userData);

  if (response.data) {
    localStorage.setItem("user", JSON.stringify(response.data));
    localStorage.setItem("token", JSON.stringify(response.data.token));
    const result = {
      user: response.data,
    };
    return result;
  } else {
    return response.data;
  }
};

//Update password
const updateAccountDetails = async ({ userDetails, token }) => {
  const userData = {
    password: userDetails.newPassword,
    //oldPassword: userDetails.oldPassword,
  };
  const member_id = userDetails.member_id;
  const user_email = userDetails.user_email;

  console.log("Token: ", token);
  console.log("UserData: ", userData);

  try {
    const response = await API.post(
      `edit_password/${member_id}/${user_email}`,
      userData
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

// dev function to update user privilige level -> change to -> update user details
const updateUserPersonal = async ({ data, token }) => {
  const user_id = data.user_id;
  const userDetails = data.userDetails; // Ensure you have the member ID

  try {
    const response = await API.post(`edit/${user_id}`, userDetails, {
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the headers if required
      },
    });
    return response.data;
  } catch (error) {
    return error.response ? error.response.data : { error: "Network Error" };
  }
};

const getUser = async (id, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const response = await API.get(`/${id}`, config);
    return response.data;
  } catch (error) {
    return error;
  }
};

const login = async (user) => {
  const { email, password } = user;
  const response = await API.post(
    "/login",
    { email, password },
    { headers: { "Content-Type": "application/json" } }
  );

  localStorage.setItem("user", JSON.stringify(response.data));
  localStorage.setItem("token", JSON.stringify(response.data.token));

  const result = {
    user: response.data,
    token: response.data.token,
  };
  return result;
};

// // get_usage
// const get_usage = async (user) => {
//   const token = JSON.parse(localStorage.getItem("token"));
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };

//   const response = await API.get(`/usage/${user}`, config);

//   return response.data;
// };

// generate api key
const generateApi = async (data) => {
  const { orgId, bot_id } = data;
  const token = JSON.parse(localStorage.getItem("token"));
  console.log({ orgId, bot_id });
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await DevAPI.get(`/generate/${orgId}/${bot_id}`, config);

  return response.data;
};

// delete api key
const deleteApi = async (key) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await DevAPI.delete(`/delete`, { apiKey: key }, config);
  return response.data;
};

// generate verificaion token
const generateVerificationToken = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await API.post(
    `/generate/verification_token`,
    { email: data.email },
    config
  );
  return response.data;
};

// generate verificaion token
const deleteVerificationToken = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };

  const response = await API.post(
    `/delete/verification_token`,
    { email: data.email },
    config
  );
  return response.data;
};

const resetUserPassword = async (data) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const email = data.email;
  const dataObj = { oldPassword: data.oldPassword, password: data.password };

  const response = await API.post(
    `/edit_user_password/${email}`,
    dataObj,
    config
  );
  return response.data;
};

//Update updateFirstTimeUser
const updateFirstTimeUser = async ({ userDetails }) => {
  const token = JSON.parse(localStorage.getItem("token"));
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  const userData = {
    status: userDetails.status,
  };
  const user_id = userDetails.user_id;

  console.log("user_id: ", user_id);
  console.log("UserData: ", userData);

  try {
    const response = await API.post(
      `update_first_time_user/${user_id}`,
      userData,
      config
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

const getUserBotMemory = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  console.log("data", data);
  const { user_id, bot_id } = data;

  try {
    const response = await API.get(`memory/${user_id}/${bot_id}`, config);
    if (!response.status.toString().startsWith("2")) {
      throw new Error(`Unexpected response code: ${response.status}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error fetching bot memory:", error);
    throw error;
  }
};

const getOTP = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("data", data);
  const { email, first_name } = data;

  try {
    const response = await API.post(
      `/send_invitation_email/${email}`,
      { first_name: first_name },
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bot memory:", error);
    throw error;
  }
};

const forgetPassword = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("data", data);
  const { email, password } = data;

  try {
    const response = await API.post(
      `/user/reset_forgot_password//${email}`,
      { password: password },
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching bot memory:", error);
    throw error;
  }
};

//bugReport
const _bugReport_ = async (data, token) => {
  if (!token) {
    token = JSON.parse(localStorage.getItem("token"));
  }

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  console.log("data", data);

  try {
    const response = await API.post(
      `/user/bug-report/${data.user_id}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error sending bug report:", error);
    throw error;
  }
};

const authService = {
  register,
  login,
  getUser,
  updateAccountDetails,
  updateUserPersonal,
  //get_usage,
  generateApi,
  deleteApi,
  generateVerificationToken,
  deleteVerificationToken,
  resetUserPassword,
  getUserBotMemory,
  getOTP,
  forgetPassword,
  updateFirstTimeUser,
  _bugReport_,
};

export default authService;
